import React, { useState, useEffect } from 'react';
import './styles.css';
//import ThaiMapCard from './components/ThaiMapCard';

const App = () => {
    const [cards, setCards] = useState([]);
    const [currentCard, setCurrentCard] = useState(0);
    const [showBack, setShowBack] = useState(false);
//    const [showMap, setShowMap] = useState(false);

    useEffect(() => {
        fetch('/api/cards')
            .then(response => response.json())
            .then(data => setCards(data));
    }, []);

    const nextCard = (e) => {
	e.stopPropagation();
        setShowBack(false);
        setCurrentCard((prevCard) => (prevCard + 1) % cards.length);
    };

    const flipCard = () => {
        setShowBack(!showBack);
    };

//    const toggleMap = () => {
//        setShowMap(!showMap);
//    };

    if (!cards.length) return <div>Loading...</div>;

    return (
	<div className="card" onClick={flipCard}>
	    {showBack ? (
		<div className="back-content">
		    <p className="back-sound">{cards[currentCard].sound}</p>
		    <p className="back-meaning">{cards[currentCard].meaning}</p>
		</div>
	    ) : (
		    <div className="front-content">
			<p className="front-word">{cards[currentCard].word}</p>
		    </div>
		)}
	    <button onClick={nextCard}>Next Card</button>
	</div>
    );
};

export default App;

